@import (inline) 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.css';

@font-face {
    font-family: 'RAG Sans';
    src: url('/assets/fonts/RAG-Sans-1.3-Regular.woff2') format('woff2'),
         url('/assets/fonts/RAG-Sans-1.3-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'RAG Sans';
    src: url('/assets/fonts/RAG-Sans-1.3-Bold.woff2') format('woff2'),
         url('/assets/fonts/RAG-Sans-1.3-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'RAG Sans';
    src: url('/assets/fonts/RAG-Sans-1.3-Light.woff2') format('woff2'),
         url('/assets/fonts/RAG-Sans-1.3-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'RAG Sans';
    src: url('/assets/fonts/RAG-Sans-1.3-SemiBold.woff2') format('woff2'),
         url('/assets/fonts/RAG-Sans-1.3-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}

html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;    
}
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overscroll-behavior: none;
    overflow: hidden;
    direction: rtl;
}

*, *:before, *:after {
    box-sizing: inherit;
}

em {
    font-style: normal;
}

a, a:visited, a:hover {
    color: inherit;
    text-decoration: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

html {
    /* HotJar style fixes */
    ._hj-widget-theme-light._hj-widget-container  * {
        font-family: 'RAG Sans' ,sans-serif !important;
        text-align: right !important;
    }

    ._hj-widget-theme-light._hj-widget-container  input[type=range] {
        direction: ltr !important;
        transform: rotate(180deg);
    }

    ._hj-widget-theme-light._hj-widget-container  datalist > option {
        text-align: center !important;
    }
    ._hj-widget-theme-light._hj-widget-container  button {
        text-align: center !important;
    }
}
